import { Flex, FlexProps, Image, MantineStyleProp } from '@mantine/core';
import { FunctionComponent, useState } from 'react';
import { LocalImages } from '../../assets/images';
import { NavbarLink, NavLink } from './components/NavbarLink/NavbarLink';

import { HotkeyItem, useHotkeys } from '@mantine/hooks';
import './SideBar.scss';

export interface SideBarProps {
  links: NavLink[];
  defaultItemSlug?: string;
  selectedStyles?: MantineStyleProp;
  expanded?: boolean;
  onItemSelected?: (slug: string) => void;
  onMenuClick?: () => void;
  style?: FlexProps;
}

export const SideBar: FunctionComponent<SideBarProps> = props => {
  const [activeLink, setActiveLink] = useState<string>(
    props.defaultItemSlug ?? ''
  );
  const elements = props.links?.map(
    (link, index) =>
      [`alt+${index + 1}`, e => handleClick(link.slug)] as HotkeyItem
  );
  useHotkeys(elements);

  const handleClick = (slug: string) => {
    setActiveLink(slug);
    props.onItemSelected?.(slug);
  };

  return (
    <Flex
      classNames={{ root: 'SideBar' }}
      direction={'column'}
      h={'100%'}
      w={'100%'}
      bg={'#EFF4F6'}
      color={'#fff'}
      fz={'1.5rem'}
      gap={'lg'}
      pt={'sm'}
      {...props.style}
    >
      <Flex w={'100%'} justify={'center'} gap={'0.5rem'}>
        <Image
          mah={props.expanded ? '2rem' : '1rem'}
          src={
            props.expanded ? LocalImages.horizontalLogo : LocalImages.smallLogo
          }
        />
      </Flex>
      {props.links?.map((link, index) => {
        return (
          <NavbarLink
            link={link}
            key={link.slug}
            active={link.slug === activeLink}
            expanded={props.expanded}
            onClick={() => handleClick(link.slug)}
          />
        );
      })}
    </Flex>
  );
};
