import { Button, Flex, TextInput } from '@mantine/core';
import { IconChartAreaLine, IconTimelineEvent } from '@tabler/icons-react';
import { useRef, useState } from 'react';
import { IoInformationCircleOutline, IoSearch } from 'react-icons/io5';
import AccountInfo from '../../components/AccountInfo/AccountInfo';
import ActivityInformation from '../../components/ActivityInformation/ActivityInformation';
import InformationTabs from '../../components/InformationTabs/InformationTabs';
import MetricsSection from '../../components/Metrics/MetricsSection';
import { ActivityFilter } from '../../data/activity-filter';
import { MetricIdentifier } from '../../data/metrics-data';
import { notImplementedFeature } from '../../data/not-implemented-function';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useDialog } from '../../hooks/use-dialog';
import { accountService } from '../../services/api-client/account-service';
import { activityService } from '../../services/api-client/activity-service';
import { metricsService } from '../../services/api-client/metrics-service';
import { MessageStatus } from '../../services/dialog';
import { withAuthProtection } from '../../services/protect-route-element';
import './HomePage.scss';

const Page = () => {
  const notification = useDialog();
  const accountNameRef = useRef<HTMLInputElement>(null);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [activityTrigger, setActivityTrigger] = useState<boolean>(false);
  const [activityFilter, setActivityFilter] = useState<ActivityFilter>();
  const [searchActive, setSearchActive] = useState<boolean>(false);

  const [accountInfoData, accountInfoDataLoading, setData] = useDataFetcher({
    serviceCall() {
      return accountService.getAccountByName(
        accountNameRef.current?.value || ''
      );
    },
    conditionForExecution: !!accountNameRef.current?.value,
    dependencies: [searchTrigger],
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Failed to fetch account data'
      );
      setData(undefined);
    },
  });

  const [questWithRewards, questWithRewardsLoading] = useDataFetcher({
    serviceCall() {
      return metricsService.getQuestsWithRewards(
        accountNameRef.current?.value || ''
      );
    },
    conditionForExecution: !!accountNameRef.current?.value,
    dependencies: [searchTrigger],
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Failed to fetch account data'
      );
    },
  });

  const [activityInfoData, activityInfoDataLoading] = useDataFetcher({
    serviceCall() {
      return activityService.getActivityByName(
        accountNameRef.current?.value || '',
        activityFilter
      );
    },
    conditionForExecution: !!accountNameRef.current?.value,
    dependencies: [searchTrigger, activityTrigger],
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Failed to fetch activity data'
      );
      setData(undefined);
    },
  });

  const handleApplyActivityFilter = (
    startDate: Date | null,
    endDate: Date | null,
    category: string | null
  ) => {
    setActivityFilter({ startDate, endDate, category });
    setActivityTrigger(!activityTrigger);
  };

  return (
    <Flex p={'1rem'} className="HomePageWrapper">
      <Flex gap={'0.3rem'} align={'flex-end'}>
        <TextInput
          ref={accountNameRef}
          placeholder="Type name or email"
          label="Account Name"
          leftSection={<IoSearch size={'1.5rem'} />}
          w={'fit-content'}
          maw={'20rem'}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setSearchTrigger(!searchTrigger);
            }
          }}
          onChange={() => setSearchActive(!!accountNameRef.current?.value)}
        />
        <Button
          aria-label={'search'}
          disabled={!searchActive || accountInfoDataLoading}
          onClick={() => {
            setSearchTrigger(!searchTrigger);
          }}
        >
          Search
        </Button>
      </Flex>
      <InformationTabs
        tabDefinitions={[
          {
            title: 'Account Info',
            icon: <IoInformationCircleOutline size={'1.5rem'} />,
            content: (
              <AccountInfo
                accountInfoData={accountInfoData}
                isLoading={accountInfoDataLoading}
                onRemoveAvatar={() => {
                  notImplementedFeature(notification);
                }}
              />
            ),
          },
          {
            title: 'Activity information',
            icon: <IconTimelineEvent size={'1.5rem'} />,
            content: (
              <ActivityInformation
                activityData={activityInfoData}
                isLoading={activityInfoDataLoading}
                isSectionActivated={
                  !!accountNameRef.current?.value || !!activityInfoData
                }
                onApplyFilter={(startDate, endDate, category) =>
                  handleApplyActivityFilter(startDate, endDate, category)
                }
                isApplyEnabled={searchActive}
              />
            ),
          },
          {
            title: 'Metrics',
            icon: <IconChartAreaLine size={'1.5rem'} />,
            content: (
              <MetricsSection
                metricsComponentsProps={[
                  {
                    title: 'Quests with rewards',
                    metricType: MetricIdentifier.QuestWithRewardsByUserMetrics,
                    data: questWithRewards,
                    activePage: 0,
                    totalPageCount: 0,
                    isLoading: questWithRewardsLoading,
                  },
                ]}
              />
            ),
          },
        ]}
      ></InformationTabs>
    </Flex>
  );
};

export const HomePage = withAuthProtection(Page);
