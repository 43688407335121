import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import { BiHomeSmile, BiUserPlus } from 'react-icons/bi';
import { PiUserListFill } from 'react-icons/pi';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from '../../components/SideBar/components/NavbarLink/NavbarLink';
import { SideBar } from '../../components/SideBar/SideBar';
import { ToolBar } from '../../components/ToolBar/ToolBar';
import { Paths } from '../../data/paths';
import { authentication } from '../../services/authentication';
import { Layout } from '../Layout/Layout';

const defaultLinks: NavLink[] = [
  { label: 'Home', slug: Paths.home, icon: <BiHomeSmile size={'26px'} /> },
  {
    label: 'Kol Invites',
    slug: Paths.kolInvites,
    icon: <BiUserPlus size={'26px'} />,
  },
];
export const MainLayout = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const user = authentication.getUser();
  const pathname = window.location.pathname.split('/');
  const tablet = useMediaQuery('(max-width: 51.25rem)');
  const mobile = useMediaQuery('(max-width: 25.875rem)');
  const [expanded, setExpanded] = useState<boolean>(tablet ? false : true);
  const [hidden, setHidden] = useState<boolean>(mobile ? true : false);

  useEffect(() => {
    setExpanded(tablet ? false : true);
    setHidden(mobile ? true : false);
  }, [tablet, mobile]);

  return (
    <Layout
      variant="FullSidebar"
      sidebarExpanded={expanded}
      sidebarHidden={hidden}
      isControlledSidebar
      sideBar={
        <SideBar
          links={defaultLinks}
          expanded={expanded}
          defaultItemSlug={pathname[pathname?.length - 1]}
          onMenuClick={() => setExpanded(false)}
          onItemSelected={slug => {
            navigation(slug);
          }}
        />
      }
      toolBar={
        <ToolBar
          location={location.pathname}
          expanded={expanded}
          onMenuClick={() => setExpanded(true)}
          name={user?.name}
          profileImage={user?.picture}
          onLogoutClick={() => authentication.logout()}
          avatarMenuItems={[
            {
              icon: <PiUserListFill size={'27px'} />,
              displayName: 'Profile',
            },
            {
              icon: <AiOutlineSetting size={'27px'} />,
              displayName: 'Settings',
            },
          ]}
        />
      }
    >
      <Outlet />
    </Layout>
  );
};
