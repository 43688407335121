import { Flex, ScrollArea, Title } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { WalletAccount } from '../../../data/account';
import { ForgeTable, Row } from '../../Table/ForgeTable';

export interface SocialsProps {
  isLoading?: boolean;
  accounts?: WalletAccount[];
}

const WalletsAccountTable: FunctionComponent<SocialsProps> = props => {
  const headers = ['Account', 'Wallet Addr', 'Link'];
  const accountLinkRegistry = new Map<string, string>([
    ['ethereum', 'https://etherscan.io/address/'],
    ['sui', 'https://suiscan.xyz/mainnet/account/'],
    ['polygon', 'https://polygonscan.com/address/'],
    ['solana', 'https://explorer.solana.com/address/'],
    ['avalanche', 'https://subnets.avax.network/c-chain/address/'],
    ['ronin', 'https://app.roninchain.com/address/'],
  ]);

  const getLink = (blockchain: string, walletAddr: string) => {
    const link = accountLinkRegistry.get(blockchain);
    if (!link) {
      return '';
    }
    return <Link to={link + walletAddr}>{walletAddr}</Link>;
  };

  const getRows = (): Row[] => {
    if (!props.accounts || props.accounts.length === 0) {
      return [];
    }
    return props.accounts.map(account => {
      return {
        elements: [
          account.blockchain,
          account.wallet_addr,
          getLink(account.blockchain, account.wallet_addr),
        ],
      };
    });
  };

  return (
    <Flex direction={'column'}>
      <Title size={'s'}>Linked Wallets</Title>
      <ScrollArea>
        <ForgeTable
          headers={headers}
          rows={getRows()}
          isLoading={props.isLoading}
        />
      </ScrollArea>
    </Flex>
  );
};

export default WalletsAccountTable;
