export enum MessageStatus {
  Warning = 'warning',
  Loading = 'loading',
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export class DialogService {
  private _provider: any;
  public constructor(provider: any) {
    this._provider = provider;
  }
  public notify(status: MessageStatus, title: string, description: string) {
    this._provider({
      position: 'top-right',
      title: title,
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  }
}
