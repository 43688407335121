import { Route, Routes } from 'react-router-dom';
import { Paths } from './data/paths';
import { EmptyLayout } from './layout/EmptyLayout/EmptyLayout';
import { MainLayout } from './layout/MainLayout/MainLayout';
import { HomePage } from './pages/HomePage/HomePage';
import { KolInvites } from './pages/KolInvites/KolInvites';
import { LoginCallbackPage } from './pages/LoginCallbackPage/LoginCallbackPage';
import { LoginPage } from './pages/LoginPage/LoginPage';

export const Kernel = () => {
  return (
    <Routes>
      <Route path={Paths.root} element={<MainLayout />}>
        <Route path={Paths.home} element={<HomePage />} />
        <Route path={Paths.kolInvites} element={<KolInvites />} />
        <Route index element={<HomePage />} />
      </Route>
      <Route path={Paths.root} element={<EmptyLayout />}>
        <Route path={Paths.login} element={<LoginPage />} />
        <Route path={Paths.loginCallback} element={<LoginCallbackPage />} />
      </Route>
    </Routes>
  );
};
