import { Loader } from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authentication } from '../../services/authentication';

const Page = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const completeLogin = async () => {
      try {
        await authentication.handleRedirectCallback();
        navigate('/home');
      } catch (err: any) {
        console.error(err);
      }
    };
    completeLogin();
  }, [navigate]);
  return (
    <div>
      <Loader size="xl" />
    </div>
  );
};

export const LoginCallbackPage = Page;
