import { ActivityFilter } from '../../data/activity-filter';
import { Event } from '../../data/event';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class ActivityService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'activity');
  }

  public async getActivityByName(
    name: string,
    activityFilter?: ActivityFilter
  ): Promise<Event[]> {
    let url = this.url(
      `${name}?category=${activityFilter?.category?.toLowerCase().replace(' ', '-') ?? 'all'}`
    );
    if (activityFilter) {
      if (activityFilter?.startDate) {
        url += `&startDate=${activityFilter.startDate.toISOString()}`;
      }
      if (activityFilter?.endDate) {
        url += `&endDate=${activityFilter.endDate.toISOString()}`;
      }
    }
    return await this.apiClient.get<Event[]>(url);
  }
}
export const activityService = new ActivityService(new BearerTokenApiClient());
