import { Box, Skeleton } from '@mantine/core';
import _ from 'lodash';

export const TableSkeletons = (amount?: number) => {
  return (
    <Box>
      {_.map(new Array(amount ?? 6), (_x, index) => {
        return <Skeleton key={index} height={20} mt={6} />;
      })}
    </Box>
  );
};
