import auth0, { AuthOptions } from 'auth0-js';
import { configuration } from '../configuration';
import { LoginData } from '../data/login-data';
import { localStorageService } from './local-storage-service';

export class Authentication {
  private _options: AuthOptions = {
    domain: configuration.userRegistry.domain,
    clientID: configuration.userRegistry.clientId,
    redirectUri: `${window.location.origin}/login-callback`,
    audience: configuration.userRegistry.audience,
    responseType: 'token id_token',
  };

  private _clearData() {
    localStorageService.clearLoginData();
    localStorageService.clearAppData();
  }

  private _setLoginData(loginData: LoginData) {
    localStorageService.setLoginData(loginData);
  }

  public async passwordlessStart(email: string): Promise<void> {
    const webAuth = new auth0.WebAuth({
      ...this._options,
    } as any);
    return new Promise((resolve, reject) => {
      webAuth.passwordlessStart(
        {
          connection: 'email',
          send: 'code',
          email: email,
        },
        (error, authResult) => {
          if (error) {
            reject(error);
            return;
          }

          if (!authResult) {
            resolve();
            return;
          }
        }
      );
    });
  }

  public async loginWithGoogle(): Promise<void> {
    const webAuth = new auth0.WebAuth({
      ...this._options,
      responseType: 'token id_token',
    });

    webAuth.authorize({
      connection: 'google-oauth2',
    });
  }

  public async passwordlessLogin(email: string, code: string): Promise<void> {
    const webAuth = new auth0.WebAuth({
      ...this._options,
    } as any);
    return new Promise((resolve, reject) => {
      webAuth.passwordlessLogin(
        {
          connection: 'email',
          email: email,
          verificationCode: code,
        },
        (error, authResult) => {
          if (error) {
            reject(error);
            return;
          }

          if (!authResult) {
            resolve();
            return;
          }
          webAuth.client.userInfo(
            authResult.accessToken as string,
            (err, user) => {
              if (err) {
                reject(err);
                return;
              }
              this._setLoginData({
                user,
                decodedHash: authResult,
              });
              resolve();
            }
          );
        }
      );
    });
  }

  public async loginWithUserAndPassword(
    email: string,
    password: string
  ): Promise<void> {
    const webAuth = new auth0.WebAuth({
      ...this._options,
    } as any);
    return new Promise((resolve, reject) => {
      webAuth.login(
        {
          email: email,
          password: password,
        },
        (error, authResult) => {
          if (error) {
            reject(error);
            return;
          }

          if (!authResult) {
            resolve();
            return;
          }
          webAuth.client.userInfo(
            authResult.accessToken as string,
            (err, user) => {
              if (err) {
                reject(err);
                return;
              }
              this._setLoginData({
                user,
                decodedHash: authResult,
              });
              resolve();
            }
          );
        }
      );
    });
  }

  public async createWithUserAndPassword(
    email: string,
    password: string
  ): Promise<void> {
    const webAuth = new auth0.WebAuth({
      ...this._options,
    } as any);
    return new Promise((resolve, reject) => {
      webAuth.signup(
        {
          connection: 'Username-Password-Authentication',
          email: email,
          password: password,
        },
        error => {
          if (error) {
            reject(error);
            return;
          }
          resolve();
        }
      );
    });
  }

  public async handleRedirectCallback(): Promise<void> {
    const webAuth = new auth0.WebAuth(this._options);

    return new Promise((resolve, reject) => {
      webAuth.parseHash((error, authResult) => {
        if (error) {
          reject(error);
          return;
        }

        if (!authResult) {
          resolve();
          return;
        }

        webAuth.client.userInfo(
          authResult.accessToken as string,
          (err, user) => {
            if (err) {
              reject(err);
              return;
            }
            this._setLoginData({
              user,
              decodedHash: authResult,
            });
            resolve();
          }
        );
      });
    });
  }

  public isAuthenticated() {
    const loginData = localStorageService.getLoginData();
    if (!loginData) {
      return false;
    }
    const exp = loginData.decodedHash.idTokenPayload['exp'] as number;
    const now = Date.now() / 1000;

    if (now <= exp) {
      return true;
    }

    return false;
  }

  public getUser(): auth0.Auth0UserProfile | null {
    const data = localStorageService.getLoginData();
    return data !== null ? data.user : null;
  }

  public getAccessToken(): string | null {
    const data = localStorageService.getLoginData();
    return !!data && !!data.decodedHash && !!data.decodedHash.accessToken
      ? data.decodedHash.accessToken
      : null;
  }

  public getDatabaseToken = async () => {
    let token: any;

    return token;
  };

  public logout() {
    this._clearData();
    const webAuth = new auth0.WebAuth(this._options);
    webAuth.logout({
      returnTo: window.location.origin + '/login',
    });
  }
}

export const authentication = new Authentication();
