import { KolInvite } from '../../data/kols-invite';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class KolService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'kol');
  }

  public async getKolInvites(): Promise<KolInvite[]> {
    return await this.apiClient.get<KolInvite[]>(this.url());
  }

  public async addNewInvite() {
    this.apiClient.post(this.url(), {});
  }
}
export const kolService = new KolService(new BearerTokenApiClient());
