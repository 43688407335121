import {
  Avatar,
  Flex,
  FlexProps,
  Menu,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';
import { BiLogOut, BiUser } from 'react-icons/bi';
import { MdArrowDropDown } from 'react-icons/md';
import { PiPencilSimpleSlash } from 'react-icons/pi';
import { data } from '../../config/config';
import './ToolBar.scss';

export interface AvatarMenuItem {
  displayName: string;
  icon: ReactNode;
  onClick?: () => void;
}
export interface ToolBarProps {
  name?: string;
  location: string;
  profileImage?: string;
  avatarMenuItems?: AvatarMenuItem[];
  expanded?: boolean;
  onLogoutClick?: () => void;
  onMenuClick?: () => void;
  onSearchClick?: (value: string) => void;
  style?: FlexProps;
}
const urlMap = {
  'kol-invites': 'Kol Invites',
  home: 'Forge Customer Support Tool',
} as { [key: string]: string };

export const ToolBar: FunctionComponent<ToolBarProps> = props => {
  const getPageTitle = () => {
    const key = props.location?.split('/')[1];
    if (Object.prototype.hasOwnProperty.call(urlMap, key)) {
      return urlMap[key];
    }
    return 'Forge Customer Support Tool';
  };
  return (
    <Flex
      w={'100%'}
      h={'100%'}
      bg={'#ffffff'}
      align={'center'}
      justify={'space-between'}
      p={'1rem 2rem'}
      gap={'md'}
      classNames={{ root: 'ToolBar' }}
      {...props.style}
    >
      <Flex align={'center'} direction={'row'} gap={'sm'}>
        <Title size={'lg'}>{getPageTitle()}</Title>
        {data.isReadOnly.some(
          (element: any) =>
            element.page === props.location && element.value === true
        ) && (
          <Tooltip label="Read Only view">
            <PiPencilSimpleSlash />
          </Tooltip>
        )}
      </Flex>
      <Menu
        classNames={{
          dropdown: 'Dropdown',
          item: 'Item',
          itemSection: 'ItemSection',
        }}
        shadow="lg"
        width={170}
        position="bottom-end"
        offset={7}
      >
        <Menu.Target>
          <Flex
            className="AvatarContainer"
            align={'center'}
            c="#1F374E"
            gap={8}
          >
            <Avatar
              variant="filled"
              size={'2rem'}
              color="#1F374E"
              alt={props.name}
              src={props.profileImage}
            >
              <BiUser size={'24px'} />
            </Avatar>
            <Flex align={'center'}>
              <Text>{props.name}</Text>
              <MdArrowDropDown size={'24px'} color="#6085A7" />
            </Flex>
          </Flex>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<BiLogOut size={'27px'} />}
            onClick={props.onLogoutClick}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
};
