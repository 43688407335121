import { Flex } from '@mantine/core';
import { FunctionComponent } from 'react';
import {
  MetricTableCard,
  MetricTableCardProps,
} from './components/MetricTableCard';

export interface MetricsSectionProps {
  metricsComponentsProps: MetricTableCardProps[];
}

const MetricsSection: FunctionComponent<MetricsSectionProps> = props => {
  return (
    <Flex className="TableMetricsSection" direction={'row'}>
      {props.metricsComponentsProps.map((metricProps, index) => (
        <MetricTableCard key={index} {...metricProps} />
      ))}
    </Flex>
  );
};

export default MetricsSection;
