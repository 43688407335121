import { Flex, Skeleton, Text, Title } from '@mantine/core';
import { FunctionComponent } from 'react';
export interface BasicInfoProps {
  lastLogin?: string;
  accountId?: string;
  email?: string;
  dateRegistered?: string;
  isLoading?: boolean;
}

const BasicInfo: FunctionComponent<BasicInfoProps> = props => {
  const getInformationElement = (title: string, value?: string) => (
    <Flex align={'center'} gap={'xs'}>
      <Title size={'sm'}>{title}:</Title>
      <Text>{value ?? 'N/A'}</Text>
    </Flex>
  );
  if (props.isLoading) {
    return (
      <Flex
        className="BasicInfoWrapper"
        direction={'column'}
        w={'100%'}
        h={'100%'}
        gap={'0.5rem'}
      >
        <Skeleton height={20} w={'5rem'} />
        <Skeleton height={20} w={'10rem'} />
        <Skeleton height={20} w={'15rem'} />
      </Flex>
    );
  }
  return (
    <Flex
      className="BasicInfoWrapper"
      direction={'column'}
      w={'100%'}
      h={'100%'}
    >
      {getInformationElement('Account ID', props.accountId)}
      {getInformationElement('Email', props.email)}
      {getInformationElement(
        'Date Registered',
        new Date(props.dateRegistered ?? '').toLocaleString()
      )}
      {getInformationElement(
        'Last activity date',
        new Date(props.lastLogin ?? '').toLocaleString()
      )}
    </Flex>
  );
};

export default BasicInfo;
