import { Flex, Skeleton } from '@mantine/core';
import { FunctionComponent } from 'react';
import { KolInvite } from '../../data/kols-invite';
import KolInviteElement from './components/KolInviteElement';

export interface KolInvitesProps {
  invites: KolInvite[];
  isLoading: boolean;
}

const KolInviteList: FunctionComponent<KolInvitesProps> = props => {
  if (props.isLoading) {
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => {
          return <Skeleton w={'100%'} h={'3rem'} />;
        })}
      </>
    );
  }

  return (
    <Flex
      className="KolInvitesWrapper"
      w={'100%'}
      gap={'sm'}
      direction={'column'}
    >
      {props.invites.map(invite => {
        return <KolInviteElement invite={invite} />;
      })}
    </Flex>
  );
};

export default KolInviteList;
