import { useState } from 'react';
import { LocalImages } from '../../assets/images';
import { Login } from '../../components/Login/Login';
import { LoginCredentials } from '../../components/Login/LoginForm/LoginForm';
import { authentication } from '../../services/authentication';

export const LoginPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleSignInWithGoogle = async () => {
    await authentication.loginWithGoogle();
  };

  const handleSignIn = async (loginCredentials: LoginCredentials) => {
    try {
      setError(undefined);
      setIsLoading(true);
      await authentication.loginWithUserAndPassword(
        loginCredentials.email,
        loginCredentials.password
      );
    } catch (e: any) {
      console.error(e);
      setError('Email and password combination not found');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Login
      isLoading={isLoading}
      logo={LocalImages.logo}
      failedSignInMessage={error}
      onSignInClick={loginCredentials => handleSignIn(loginCredentials)}
      onSignInWithGoogleClick={() => handleSignInWithGoogle()}
    />
  );
};
