export const configuration = {
  cedApiKey: "2857cf0f3ddc705c5d6932531681d928ab109c96c17696c89dd1ec487e278dd6844f1c68ffd7b6762890f22e6a1829e6f57e",
  environment: "development",
  version: "1.0.47",
  backendApi: {
    url: "https://api.customer-support-tool-dev.forge.gg",
    avatarUrl: "https://develop.forge.gg/avatar/",
  },
  userRegistry: {
    domain: "forge-support.us.auth0.com",
    managementClientId: "fDWvc8zsxIFx6IbYFDbIduIJXvTW77mQ",
    managementClientSecret: "LjrTfmMQ_yFdqixjdm-WXSrHj8dbrcFZ6FhgavZ6jxZ2fMBsjNsBfZ9GOL3tMnsK",
    clientId: "DLn59Srp8ybzBDX3EMqRh1zHGtYtI5vV",
    audience: "https://forge-support.us.auth0.com/api/v2/",
  },
};