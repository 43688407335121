import { QuestWithRewardsByUserMetrics } from '../../data/metrics-data';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class MetricsService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'metrics');
  }

  public async getQuestsWithRewards(
    name: string
  ): Promise<QuestWithRewardsByUserMetrics[]> {
    return await this.apiClient.get<QuestWithRewardsByUserMetrics[]>(
      this.url(name)
    );
  }
}
export const metricsService = new MetricsService(new BearerTokenApiClient());
