import {
  Flex,
  FlexProps,
  Image,
  MantineStyleProp,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { FunctionComponent } from 'react';
import './Login.scss';
import { LoginCredentials, LoginForm } from './LoginForm/LoginForm';

export interface LoginProps {
  logo?: string;
  companyName?: string;
  contactUsMessage?: string;
  termsAgreementMessage?: string;
  passwordRegex?: RegExp;
  passwordErrorMessage?: string;
  formContainerStyles?: MantineStyleProp;
  isLoading?: boolean;
  failedSignInMessage?: string;
  onContactUsClick?: () => void;
  onSignInClick?: (loginCredentials: LoginCredentials) => void;
  onSignInWithGoogleClick?: () => void;
  style?: FlexProps;
}

export const Login: FunctionComponent<LoginProps> = props => {
  return (
    <Flex
      className="login-page"
      justify={'center'}
      align={'center'}
      w={'100%'}
      h="100vh"
      {...props.style}
    >
      <Paper
        className="login-container"
        maw="30rem"
        p={'1.5rem 2.5rem'}
        shadow={'xl'}
        radius={'md'}
        style={props.formContainerStyles ?? {}}
      >
        <Stack gap={'2rem'}>
          <Stack align="center">
            {props.logo && (
              <Image
                className="LogoImage"
                maw="12rem"
                mah="5rem"
                fit="contain"
                src={'https://forge.gg/assets/img/forge-logo.svg'}
                alt={props.companyName ?? ''}
              />
            )}
          </Stack>
          <LoginForm
            isLoading={props.isLoading}
            passwordRegex={props.passwordRegex}
            passwordErrorMessage={props.passwordErrorMessage}
            failedSignInMessage={props.failedSignInMessage}
            onSignInClick={loginCredentials =>
              props.onSignInClick?.(loginCredentials)
            }
            onSignInWithGoogleClick={() => props.onSignInWithGoogleClick?.()}
          />
          <Text size="xs" color="subtle" ta="center">
            {props.termsAgreementMessage ??
              'By continuing, you acknowledge that you have read, understood, and agree to our terms and condition'}
          </Text>
        </Stack>
      </Paper>
    </Flex>
  );
};
