import {
  ActionIcon,
  Anchor,
  Badge,
  Card,
  CopyButton,
  Flex,
  rem,
  Text,
} from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { FunctionComponent } from 'react';
import { KolInvite } from '../../../data/kols-invite';

export interface KolInviteProps {
  invite: KolInvite;
}

const KolInviteElement: FunctionComponent<KolInviteProps> = props => {
  return (
    <Card w={'100%'} h={'100%'} shadow="sm" padding="lg" radius="md" withBorder>
      <Flex
        direction={'row'}
        w={'100%'}
        gap={'1rem'}
        align={'center'}
        justify={'space-between'}
      >
        <Flex>
          <CopyButton
            value={`https://kolverse.xyz/signup/${props.invite.id}` ?? ''}
            timeout={2000}
          >
            {({ copied, copy }) => (
              <ActionIcon
                color={copied ? 'teal' : 'gray'}
                variant="subtle"
                onClick={copy}
              >
                {copied ? (
                  <IconCheck style={{ width: rem(16) }} />
                ) : (
                  <IconCopy style={{ width: rem(16) }} />
                )}
              </ActionIcon>
            )}
          </CopyButton>
          <Anchor
            href={`https://kolverse.xyz/signup/${props.invite.id}`}
            target="_blank"
            underline="never"
          >
            {`https://kolverse.xyz/signup/${props.invite.id}`}
          </Anchor>
        </Flex>
        <Text>{props.invite.user ? `User: ${props.invite.user}` : ''}</Text>
        <Badge bg={props.invite.status === 'claimed' ? 'green' : 'orange'}>
          {props.invite.status}
        </Badge>
      </Flex>
    </Card>
  );
};

export default KolInviteElement;
