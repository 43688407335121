import { Tabs, Tooltip } from '@mantine/core';
import { HotkeyItem, useHotkeys } from '@mantine/hooks';
import { FunctionComponent, ReactNode, useState } from 'react';

export interface InformationTabsProps {
  tabDefinitions: TabDefinition[];
}
export interface TabDefinition {
  title: string;
  icon: ReactNode;
  content: ReactNode;
}

const InformationTabs: FunctionComponent<InformationTabsProps> = props => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const elements = props.tabDefinitions?.map(
    (_, index) =>
      [`mod+${index + 1}`, e => setSelectedIndex(+e.key - 1)] as HotkeyItem
  );
  useHotkeys(elements);
  const getTabsTitles = () => {
    return props.tabDefinitions?.map((tab, index) => {
      return (
        <Tooltip
          key={`tooltip-${index}`}
          label={`ctrl+${index + 1}`}
          openDelay={800}
        >
          <Tabs.Tab
            key={`tab-${index}`}
            value={tab.title.toLowerCase()}
            leftSection={tab.icon}
            onClick={() => setSelectedIndex(index)}
          >
            {tab.title}
          </Tabs.Tab>
        </Tooltip>
      );
    });
  };
  const getTabPanels = () => {
    return props.tabDefinitions?.map((tab, index) => {
      return (
        <Tabs.Panel key={`tabs-panel-${index}`} value={tab.title.toLowerCase()}>
          {tab.content}
        </Tabs.Panel>
      );
    });
  };
  return (
    <Tabs
      color="indigo"
      variant="outline"
      radius="md"
      value={props.tabDefinitions[selectedIndex].title.toLowerCase()}
      defaultValue={props.tabDefinitions[0].title.toLowerCase()}
    >
      <Tabs.List>{getTabsTitles()}</Tabs.List>
      {getTabPanels()}
    </Tabs>
  );
};

export default InformationTabs;
