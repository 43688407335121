import { Highlight } from '@chakra-ui/react';
import {
  Button,
  Card,
  Checkbox,
  Flex,
  ScrollAreaAutosize,
  Select,
  TextInput,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { FC, useEffect, useRef, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { Event } from '../../data/event';
import { ForgeTable } from '../Table/ForgeTable';

export interface ActivityInformationProps {
  onApplyFilter?: (
    startDate: Date | null,
    endDate: Date | null,
    category: string | null
  ) => void;
  activityData?: Event[];
  isSectionActivated: boolean;
  isApplyEnabled: boolean;
  isLoading?: boolean;
}

const ActivityInformation: FC<ActivityInformationProps> = props => {
  const [allEvents, setAllEvents] = useState(true);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [category, setCategory] = useState<string | null>(null);
  const [isApplyEnabled, setIsApplyEnabled] = useState(props.isApplyEnabled);
  const searchText = useRef<HTMLInputElement>(null);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filteredBySearchEvents, setFilteredBySearchEvents] = useState<Event[]>(
    []
  );

  useEffect(() => {
    setIsApplyEnabled(props.isApplyEnabled);
  }, [props.isApplyEnabled]);

  useEffect(() => {
    if (searchText.current) {
      searchText.current.value = '';
    }
    setFilteredBySearchEvents(props.activityData || []);
  }, [props.activityData]);

  useEffect(() => {
    if (!searchText?.current?.value || searchText?.current?.value === '') {
      setFilteredBySearchEvents(props.activityData || []);
      return;
    }
    const filteredEvents = props.activityData?.filter(event => {
      const searchTextValue = (
        searchText?.current?.value as string
      ).toLowerCase();
      return (
        event.subDescription
          ?.toString()
          .toLowerCase()
          .includes(searchTextValue) ||
        event.description?.toString().toLowerCase().includes(searchTextValue) ||
        event.action?.toString().toLowerCase().includes(searchTextValue) ||
        event.newValue?.toString().toLowerCase().includes(searchTextValue) ||
        event.oldValue?.toString().toLowerCase().includes(searchTextValue) ||
        event.type?.toString().toLowerCase().includes(searchTextValue) ||
        event.createdAt?.toString().toLowerCase().includes(searchTextValue) ||
        event.name?.toString().toLowerCase().includes(searchTextValue) ||
        event.id?.toLowerCase().includes(searchTextValue)
      );
    });
    setFilteredBySearchEvents(filteredEvents || []);
  }, [searchTrigger]);

  useEffect(() => {
    if (allEvents) {
      setStartDate(null);
      setEndDate(null);
      setCategory('All');
    }
  }, [allEvents]);

  useEffect(() => {
    if (allEvents) {
      setIsApplyEnabled(true);
      return;
    }
    if (!startDate && !endDate) {
      setIsApplyEnabled(true);
      return;
    }
    if (startDate && endDate) {
      setIsApplyEnabled(true);
      return;
    }
    setIsApplyEnabled(false);
  }, [startDate, endDate, category, allEvents]);

  const getRowElement = (value: string) => {
    if (!value || value?.length === 0) {
      return '';
    }
    return (
      <Highlight
        query={(searchText?.current?.value ?? '') as string}
        styles={{ bg: 'orange.100' }}
      >
        {value?.toString()}
      </Highlight>
    );
  };
  const getRows = () => {
    if (filteredBySearchEvents) {
      return filteredBySearchEvents.map(event => {
        return {
          elements: [
            getRowElement(new Date(event.createdAt).toLocaleString()),
            getRowElement(event.type),
            getRowElement(event.description),
            getRowElement(event.subDescription),
            getRowElement(event.action),
            getRowElement(event.oldValue),
            getRowElement(event.newValue),
          ],
        };
      });
    }
    return [];
  };

  if (!props.isSectionActivated) {
    return (
      <center>
        <Card
          className="AccountInfoWrapper"
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
        >
          Need to introduce an user in order to see the activity information
        </Card>
      </center>
    );
  }
  return (
    <Card
      className="ActivityInformationWrapper"
      w={'100%'}
      h={'100%'}
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
    >
      <Flex
        className="ActivityInformationContent"
        direction={'column'}
        gap={'lg'}
      >
        <Flex
          className="TopSectionWrapper"
          direction={'row'}
          gap={'lg'}
          justify={'space-between'}
        >
          <Flex
            className="LeftSection"
            direction={'row'}
            gap={'lg'}
            align={'center'}
          >
            <DateTimePicker
              disabled={allEvents || props.isLoading}
              label="Pick start date and time"
              placeholder="Pick start date and time"
              onChange={e => setStartDate(e)}
              value={startDate}
              clearable
            />
            <DateTimePicker
              disabled={allEvents || props.isLoading}
              label="Pick end date and time"
              placeholder="Pick start date and time"
              onChange={e => setEndDate(e)}
              value={endDate}
              clearable
            />
            <Select
              disabled={allEvents || props.isLoading}
              label="Categories"
              placeholder="Select a category"
              defaultValue="All"
              data={[
                'All',
                'Rewards',
                'Account Link',
                'Quest',
                'Level',
                'Error',
              ]}
              onChange={e => setCategory(e)}
              value={category}
            />
            <Checkbox
              disabled={props.isLoading}
              pt={'0.5rem'}
              label="All events"
              checked={allEvents}
              onChange={e => setAllEvents(e.currentTarget.checked)}
            />
            <Flex gap={'0.3rem'} align={'flex-end'}>
              <TextInput
                ref={searchText}
                placeholder=""
                label=" "
                leftSection={<IoSearch size={'1.5rem'} />}
                w={'fit-content'}
                maw={'20rem'}
                disabled={props.isLoading}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setSearchTrigger(!searchTrigger);
                  }
                }}
              />
              <Button
                aria-label={'search'}
                disabled={!searchText || props.isLoading}
                onClick={() => {
                  setSearchTrigger(!searchTrigger);
                }}
              >
                Search
              </Button>
            </Flex>
          </Flex>
          <Flex className="RightSection" pt={'1rem'}>
            <Button
              aria-label={'search'}
              disabled={!isApplyEnabled || props.isLoading}
              onClick={() =>
                props.onApplyFilter?.(startDate, endDate, category)
              }
            >
              Apply
            </Button>
          </Flex>
        </Flex>
        <ScrollAreaAutosize w={'100%'} h={'35rem'}>
          <ForgeTable
            isLoading={props.isLoading}
            rows={getRows()}
            headers={[
              'Date-Time',
              'Category',
              'Main Detail',
              'Sub Detail',
              'Action',
              'Old Value',
              'New Value',
            ]}
          ></ForgeTable>
        </ScrollAreaAutosize>
      </Flex>
    </Card>
  );
};
export default ActivityInformation;
