import {
  Card,
  Flex,
  Group,
  Pagination,
  ScrollAreaAutosize,
  Title,
} from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';
import { GiHillConquest } from 'react-icons/gi';
import {
  MetricIdentifier,
  Metrics,
  QuestWithRewardsByUserMetrics,
} from '../../../data/metrics-data';
import { ForgeTable, Row } from '../../Table/ForgeTable';

export interface MetricTableCardProps {
  activePage: number;
  title: string;
  metricType: MetricIdentifier;
  data?: Metrics[];
  isLoading?: boolean;
  onPageChange?: (page: number) => void;
  totalPageCount: number;
}

export const MetricTableCard: FunctionComponent<
  MetricTableCardProps
> = props => {
  const getRows = (): Row[] => {
    if (!props.data) {
      return [];
    }
    return props.data?.map(data => {
      return (
        metricOutputMap.get(props.metricType)?.row(data) ?? { elements: [] }
      );
    });
  };

  return (
    <Flex direction={'column'} w={'100%'} align={'end'} gap={'lg'}>
      <Card
        shadow="sm"
        w={'100%'}
        padding="lg"
        radius="md"
        withBorder
        bg={'#eff4f6'}
      >
        <Group>
          <Flex
            w={'100%'}
            direction={'row'}
            align={'end'}
            justify={'space-between'}
          >
            <Title size={'md'} pb={'1rem'}>
              {props.title}
            </Title>
            {metricOutputMap.get(props.metricType)?.icon}
          </Flex>
        </Group>
        <Group justify="space-between" mt="md" mb="xs"></Group>
        <Group>
          <ScrollAreaAutosize w={'100%'} h={'35rem'}>
            <ForgeTable
              skeletonAmount={3}
              isLoading={props.isLoading}
              headers={metricOutputMap.get(props.metricType)?.headers() ?? []}
              rows={getRows()}
            />
          </ScrollAreaAutosize>
        </Group>
      </Card>
      {!props.isLoading && (
        <Pagination
          total={props.totalPageCount}
          onChange={page => {
            props.onPageChange?.(page);
          }}
          value={props.activePage}
        />
      )}
    </Flex>
  );
};

export const metricOutputMap = new Map<
  MetricIdentifier,
  { row: (data: Metrics) => Row; headers: () => string[]; icon: ReactNode }
>([
  [
    MetricIdentifier.QuestWithRewardsByUserMetrics,
    {
      row: (data: Metrics) =>
        mapCompletedQuestsWithUserCount(data as QuestWithRewardsByUserMetrics),
      headers: () => ['Quest', 'Total rewards'],
      icon: <GiHillConquest size={40} />,
    },
  ],
]);

const mapCompletedQuestsWithUserCount = (
  data: QuestWithRewardsByUserMetrics
): Row => {
  return {
    elements: [data.questName, data.totalRewards],
  } as Row;
};
