import { SkeletonCircle } from '@chakra-ui/react';
import { Avatar, Button, Flex, Skeleton } from '@mantine/core';
import { FunctionComponent } from 'react';

export interface AccountAvatarProps {
  isLoading?: boolean;
  avatar?: string;
  onclick?: () => void;
  onRemoveAvatar?: () => void;
}
const AccountAvatar: FunctionComponent<AccountAvatarProps> = props => {
  if (props.isLoading) {
    return (
      <Flex
        className="BasicInfoWrapper"
        direction={'column'}
        w={'100%'}
        h={'100%'}
        align={'center'}
        gap={'0.5rem'}
      >
        <SkeletonCircle height={20} w={'5rem'} />
        <Skeleton height={20} w={'10rem'} />
      </Flex>
    );
  }
  return (
    <Flex direction={'column'} align={'center'} gap={'xs'}>
      <Avatar
        variant="default"
        radius={'10rem'}
        w={'5rem'}
        h={'5rem'}
        src={props.avatar}
        onClick={() => props.onclick?.()}
      />
      <Button size="compact-xs" onClick={() => props.onRemoveAvatar?.()}>
        Remove Avatar
      </Button>
    </Flex>
  );
};

export default AccountAvatar;
