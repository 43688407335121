import { LocalImages } from '../assets/images';

export const data = {
  companyName: 'Forge Inc',
  primaryColor: 'black',
  darkerColor: '#f2f2f2',
  secondaryColor: '#1B1B1B',
  linearGradient: ['white', 'white'],
  '404': '404 Page not found',
  contactUri: '',
  Navbar: [],
  icon: LocalImages.logo,
  smallIcon: '',
  isReadOnly: [{ page: '/home', value: false }],
  isKolsReadOnly: true,
  isFeaturedTweetsReadOnly: false,
};
