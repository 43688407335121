import { Flex } from '@mantine/core';
import { Outlet } from 'react-router-dom';

export const EmptyLayout = () => {
  return (
    <Flex
      className="emptyLayout"
      direction="column"
      align="center"
      justify="center"
      w="100vw"
      h="100vh"
    >
      <Outlet />
    </Flex>
  );
};
