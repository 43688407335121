import {
  Button,
  Center,
  Flex,
  FlexProps,
  Loader,
  PasswordInput,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FunctionComponent } from 'react';

export interface LoginCredentials {
  email: string;
  password: string;
}
export interface LoginFormProps extends FlexProps {
  passwordRegex?: RegExp;
  passwordErrorMessage?: string;
  isLoading?: boolean;
  failedSignInMessage?: string;
  onSignInClick?: (loginCredentials: LoginCredentials) => void;
  onSignInWithGoogleClick?: () => void;
}

export const LoginForm: FunctionComponent<LoginFormProps> = props => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = props.passwordRegex ?? /^.{8,}$/;

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: value => (emailRegex.test(value) ? null : 'Invalid email'),
      password: value =>
        passwordRegex.test(value)
          ? null
          : props.passwordErrorMessage ??
            'Password must be at least 8 characters',
    },
  });

  return (
    <form onSubmit={form.onSubmit(values => props.onSignInClick?.(values))}>
      {props.failedSignInMessage && (
        <Text c={'red'}>{props.failedSignInMessage}</Text>
      )}
      <Flex direction={'column'} gap={'0.75rem'}>
        <TextInput
          type="email"
          disabled={props.isLoading}
          placeholder="Email"
          {...form.getInputProps('email')}
        ></TextInput>
        <PasswordInput
          disabled={props.isLoading}
          placeholder="Password"
          {...form.getInputProps('password')}
        ></PasswordInput>

        {props.isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <Flex gap={'0.75rem'} direction={'column'}>
            <Button
              variant="light"
              type="submit"
              disabled={!form.isValid('email') || !form.isValid('password')}
            >
              Sign In
            </Button>
            <Button
              variant="dark"
              className="btnSignInGoogle"
              onClick={() => props.onSignInWithGoogleClick?.()}
            >
              Sign In with Google
            </Button>
          </Flex>
        )}
      </Flex>
    </form>
  );
};
