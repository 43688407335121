import { LoginData } from '../data/login-data';

enum localStorageKey {
  LoginData = 'loginData',
  isFirstRule = 'isFirstRule',
}

class LocalStorageService {
  public clearLoginData() {
    localStorage.removeItem(localStorageKey.LoginData);
  }

  public clearAppData() {
    localStorage.removeItem('apiKeys');
  }

  public setLoginData(loginData: LoginData) {
    localStorage.setItem(localStorageKey.LoginData, JSON.stringify(loginData));
  }

  public getLoginData(): LoginData | null {
    const data = localStorage.getItem(localStorageKey.LoginData);
    return this._parseData<LoginData>(data);
  }

  private _parseData<T>(data: any): T {
    return data !== null ? JSON.parse(data) : null;
  }

  public isFirstRule(): boolean {
    const isFirstRule = localStorage.getItem(localStorageKey.isFirstRule);
    return isFirstRule === 'true' || isFirstRule === null;
  }
  public setIsFirstRule(isFirstRule: boolean) {
    localStorage.setItem(localStorageKey.isFirstRule, `${isFirstRule}`);
  }
}

export const localStorageService = new LocalStorageService();
