// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Metrics {}

export interface QuestWithRewardsByUserMetrics extends Metrics {
  questName: string;
  totalRewards: number;
}

export interface PaginatedMetic {
  metrics: Metrics[];
  pageCount: number;
}

export enum MetricIdentifier {
  QuestWithRewardsByUserMetrics,
}
